var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./malli.core.js");require("./metabase.lib.convert.js");require("./metabase.lib.dispatch.js");require("./metabase.lib.expression.js");require("./metabase.lib.hierarchy.js");require("./metabase.lib.metadata.js");require("./metabase.lib.metadata.calculation.js");require("./metabase.lib.normalize.js");require("./metabase.lib.schema.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.id.js");require("./metabase.lib.util.js");require("./metabase.mbql.util.js");require("./metabase.util.js");require("./metabase.util.malli.js");
'use strict';var Z0,$0,d1,Ppa,Opa,Qpa;$CLJS.W0=function(a,b){return $CLJS.D(a)<=$CLJS.D(b)&&$CLJS.Le(function(c){return $CLJS.Dd(b,c)},a)};$CLJS.X0=function(a,b){return $CLJS.Jz($CLJS.Oe(a),b)};$CLJS.Y0=function(a){var b;a:for(b=a;;)if($CLJS.td(b))b=$CLJS.Hi.h(b);else{b=$CLJS.Bd(b)?b:null;break a}if($CLJS.n(b))return b;throw Error($CLJS.gD("Not something with an ID: {0}",$CLJS.H([$CLJS.xh.l($CLJS.H([a]))])));};
Z0=function(a){if(null!=a&&null!=a.sg)a=a.sg(a);else{var b=Z0[$CLJS.va(null==a?null:a)];if(null!=b)a=b.h?b.h(a):b.call(null,a);else if(b=Z0._,null!=b)a=b.h?b.h(a):b.call(null,a);else throw $CLJS.$a("MetadataProvider.database",a);}return a};$0=function(a,b){if(null!=a&&null!=a.tg)a=a.tg(a,b);else{var c=$0[$CLJS.va(null==a?null:a)];if(null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else if(c=$0._,null!=c)a=c.g?c.g(a,b):c.call(null,a,b);else throw $CLJS.$a("MetadataProvider.field",a);}return a};$CLJS.a1=function(a){return Z0($CLJS.e_(a))};
$CLJS.b1=function(a,b){return $0($CLJS.e_(a),b)};$CLJS.c1=function(a){var b=$CLJS.FV(a,0),c=$CLJS.O(b),d=$CLJS.K.g(c,$CLJS.fO),e=$CLJS.K.g(c,$CLJS.OU);return $CLJS.Ad(function(){var f=$CLJS.a1(a);$CLJS.n(f)?(f=$CLJS.O(f),f=$CLJS.K.g(f,$CLJS.Hi),f=$CLJS.E.g($CLJS.VO.h(a),f)):f=null;if($CLJS.n(f)){f=$CLJS.n(d)?$CLJS.f_(a,d):d;if($CLJS.n(f))return f;f=$CLJS.n(e)?$CLJS.h_(a,e):e;return $CLJS.n(f)?f:$CLJS.E.g($CLJS.Vs.h(c),$CLJS.bV)&&$CLJS.E.g($CLJS.cZ,$CLJS.dZ.h($CLJS.a1(a)))}return f}())};
d1=function(a,b){return function f(d,e){try{if($CLJS.vd(e)&&3===$CLJS.D(e))try{var k=$CLJS.F(e,0);if($CLJS.ce(k,$CLJS.mF))try{var l=$CLJS.F(e,1);if($CLJS.n($CLJS.eD($CLJS.td,$CLJS.Oe($CLJS.eD($CLJS.tA,$CLJS.oi)))(l)))try{var m=$CLJS.F(e,2);if($CLJS.n($CLJS.eD($CLJS.Bd,$CLJS.rl)(m))){var t=$CLJS.F(e,2),u=$CLJS.F(e,1);return $CLJS.n($CLJS.Me(new $CLJS.Rg(null,new $CLJS.h(null,1,[Opa,null],null),null),d))?e:$CLJS.gk.v(e,1,$CLJS.vk,null!=$CLJS.tA.h(u)&&$CLJS.ck.g($CLJS.tA.h(u),$CLJS.tA.h($CLJS.b1(b,t)))?
null:$CLJS.vk.l($CLJS.H([$CLJS.Dd(u,$CLJS.tA)?null:new $CLJS.h(null,1,[$CLJS.LX,!0],null),$CLJS.Cl($CLJS.b1(b,t),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.oi],null))])))}throw $CLJS.Z;}catch(x){if(x instanceof Error){var v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof Error){v=x;if(v===$CLJS.Z)throw $CLJS.Z;throw v;}throw x;}else throw $CLJS.Z;}catch(x){if(x instanceof
Error){v=x;if(v===$CLJS.Z)return $CLJS.YV(f,d,e);throw v;}throw x;}}($CLJS.xf,a)};Ppa=new $CLJS.M(null,"is-native","is-native",-1797717934);Opa=new $CLJS.M("mbql","stage-metadata","mbql/stage-metadata",1090845629);Qpa=new $CLJS.M(null,"is-editable","is-editable",269018020);$CLJS.T0.m(null,$CLJS.hV,function(a){return $CLJS.S0(a,new $CLJS.h(null,2,[$CLJS.Si,$CLJS.mh,$CLJS.pK,$CLJS.Qe($CLJS.fk,$CLJS.T0)],null))});$CLJS.V_.m(null,$CLJS.hV,function(){throw $CLJS.Uh("You can't calculate a metadata map for a query! Use lib.metadata.calculation/returned-columns-method instead.",$CLJS.N);});$CLJS.X_.m(null,$CLJS.hV,function(a,b,c,d){return $CLJS.Y_.v(a,b,$CLJS.FV(c,b),d)});$CLJS.O_.m(null,$CLJS.hV,function(a,b,c,d){return $CLJS.n_.v(a,b,$CLJS.FV(c,b),d)});
$CLJS.W_.m(null,$CLJS.hV,function(a,b,c){a=$CLJS.FV(c,0);a=$CLJS.E.g($CLJS.Vs.h(a),$CLJS.bV);return new $CLJS.h(null,2,[Ppa,a,Qpa,$CLJS.c1(c)],null)});$CLJS.e1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j($CLJS.N,$CLJS.Pi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","can-run-method"),function(f){return $CLJS.Vs.h($CLJS.FV(f,0))},e,a,b,c,d)}();$CLJS.e1.m(null,$CLJS.Oh,function(){return!0});
$CLJS.f1=function(){function a(d,e,f){return new $CLJS.h(null,4,[$CLJS.Vs,$CLJS.hV,$CLJS.hD,$CLJS.e_(e),$CLJS.VO,d,$CLJS.pK,f],null)}function b(d,e){var f=$CLJS.Hi.h($CLJS.a1(d));return c.j?c.j(f,d,e):c.call(null,f,d,e)}var c=null;c=function(d,e,f){switch(arguments.length){case 2:return b.call(this,d,e);case 3:return a.call(this,d,e,f)}throw Error("Invalid arity: "+arguments.length);};c.g=b;c.j=a;return c}();
$CLJS.g1=function(){var a=$CLJS.Se($CLJS.N),b=$CLJS.Se($CLJS.N),c=$CLJS.Se($CLJS.N),d=$CLJS.Se($CLJS.N),e=$CLJS.K.j(new $CLJS.h(null,1,[$CLJS.Pi,$CLJS.fB],null),$CLJS.Pi,$CLJS.Gh.o?$CLJS.Gh.o():$CLJS.Gh.call(null));return new $CLJS.Ph($CLJS.qh.g("metabase.lib.query","query-method"),function(f,k){return $CLJS.WA(k)},e,a,b,c,d)}();$CLJS.g1.m(null,$CLJS.pA,function(a,b){a:{var c=$CLJS.QD;$CLJS.QD=!0;try{var d=$CLJS.YX.h(b);break a}finally{$CLJS.QD=c}d=void 0}b=d1(d,a);return $CLJS.f1.g(a,$CLJS.pK.h(b))});
$CLJS.g1.m(null,$CLJS.hV,function(a,b){var c=$CLJS.O(b);b=$CLJS.K.g(c,$CLJS.UX);var d=$CLJS.e_(a),e=$CLJS.Wj.g($CLJS.R.j(c,$CLJS.hD,d),$CLJS.UX);a=$CLJS.pK.h(e);return $CLJS.n(b)?$CLJS.R.j(e,$CLJS.pK,$CLJS.fk.g(function(f){var k=$CLJS.I(f,0,null);f=$CLJS.I(f,1,null);return function u(m,t){try{if($CLJS.vd(t)&&3===$CLJS.D(t))try{var v=$CLJS.F(t,0);if($CLJS.ce(v,$CLJS.qA))try{var x=$CLJS.F(t,1);if($CLJS.n($CLJS.eD($CLJS.td,$CLJS.Oe($CLJS.eD($CLJS.tA,$CLJS.oi)))(x))){var A=$CLJS.F(t,1),C=$CLJS.F(t,2),
G=function(){try{return $CLJS.X0(function(S){return $CLJS.E.g($CLJS.cj,S)},$CLJS.Cl($CLJS.dd($CLJS.Gpa.j(e,k,C)),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.tA,$CLJS.oi],null)))}catch(S){return null}}();return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.qA,$CLJS.vk.l($CLJS.H([G,A])),C],null)}throw $CLJS.Z;}catch(S){if(S instanceof Error){var J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)throw $CLJS.Z;throw J;}throw S;}else throw $CLJS.Z;
}catch(S){if(S instanceof Error){J=S;if(J===$CLJS.Z)return $CLJS.YV(u,m,t);throw J;}throw S;}}($CLJS.xf,d1(f,d))},$CLJS.Bl($CLJS.xr,a))):e});$CLJS.g1.m(null,$CLJS.SJ,function(a,b){return $CLJS.f1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.VU,$CLJS.fO,$CLJS.Y0(b)],null)],null))});$CLJS.g1.m(null,$CLJS.gK,function(a,b){return $CLJS.f1.g(a,new $CLJS.P(null,1,5,$CLJS.Q,[new $CLJS.h(null,2,[$CLJS.Vs,$CLJS.VU,$CLJS.OU,$CLJS.Y0(b)],null)],null))});