var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./clojure.set.js");require("./metabase.util.malli.registry.js");
'use strict';var lha,mha,nha,oha,pha,qha,rha;$CLJS.VF=new $CLJS.M(null,"second","second",-444702010);$CLJS.WF=new $CLJS.M(null,"second-of-minute","second-of-minute",222734326);$CLJS.XF=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time","metabase.lib.schema.temporal-bucketing/unit.date-time",1924841408);$CLJS.YF=new $CLJS.M(null,"millisecond","millisecond",-540123566);$CLJS.ZF=new $CLJS.M(null,"year-of-era","year-of-era",682445876);
lha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.extract","metabase.lib.schema.temporal-bucketing/unit.date-time.extract",1230935001);mha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time","metabase.lib.schema.temporal-bucketing/unit.time",713582593);$CLJS.$F=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date-time.truncate","metabase.lib.schema.temporal-bucketing/unit.date-time.truncate",686665771);
$CLJS.aG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.interval","metabase.lib.schema.temporal-bucketing/unit.time.interval",-718964325);$CLJS.bG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date","metabase.lib.schema.temporal-bucketing/unit.date",1876937510);nha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.extract","metabase.lib.schema.temporal-bucketing/unit.time.extract",-456342719);
oha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.truncate","metabase.lib.schema.temporal-bucketing/unit.date.truncate",1199064479);pha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.extract","metabase.lib.schema.temporal-bucketing/unit.date.extract",1595055425);qha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","option","metabase.lib.schema.temporal-bucketing/option",-1756789401);
$CLJS.cG=new $CLJS.M("option","temporal-bucketing","option/temporal-bucketing",-108914562);$CLJS.dG=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.date.interval","metabase.lib.schema.temporal-bucketing/unit.date.interval",-1463811525);rha=new $CLJS.M("metabase.lib.schema.temporal-bucketing","unit.time.truncate","metabase.lib.schema.temporal-bucketing/unit.time.truncate",-948820335);var eG=new $CLJS.P(null,8,5,$CLJS.Q,[$CLJS.pi,$CLJS.hj,$CLJS.gj,$CLJS.di,$CLJS.Cj,$CLJS.ki,$CLJS.Wh,$CLJS.ZF],null),fG=$CLJS.Wg(eG),sha,tha,uha;$CLJS.Y(pha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date extraction unit"],null)],null),fG));var gG=new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.Kk,$CLJS.Ui,$CLJS.$i,$CLJS.ci,$CLJS.Wh],null),hG=$CLJS.Wg(gG);
$CLJS.Y(oha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date truncation unit"],null)],null),hG));$CLJS.iG=$CLJS.Wf.j($CLJS.xf,$CLJS.wk.o(),$CLJS.bf.g(gG,eG));sha=$CLJS.Wg($CLJS.iG);$CLJS.Y($CLJS.bG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date bucketing unit"],null)],null),sha));var jG=new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.WF,$CLJS.Qi,$CLJS.Ak],null),kG=$CLJS.Wg(jG);
$CLJS.Y(nha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time extraction unit"],null)],null),kG));var lG=new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.YF,$CLJS.VF,$CLJS.Dk,$CLJS.Jk],null),mG=$CLJS.Wg(lG);$CLJS.Y(rha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time truncation unit"],null)],null),mG));$CLJS.nG=$CLJS.Wf.j($CLJS.xf,$CLJS.wk.o(),$CLJS.bf.g(lG,jG));tha=$CLJS.Wg($CLJS.nG);
$CLJS.Y(mha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time bucketing unit"],null)],null),tha));$CLJS.oG=$CLJS.Wf.j($CLJS.xf,$CLJS.wk.o(),$CLJS.bf.l(lG,gG,$CLJS.H([jG,eG])));uha=$CLJS.Wg($CLJS.oG);$CLJS.Y($CLJS.XF,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime bucketing unit"],null)],null),$CLJS.oG));var vha=$CLJS.Yd.g(uha,$CLJS.Oh);
$CLJS.Y($CLJS.dD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid temporal bucketing unit"],null)],null),vha));$CLJS.pG=$CLJS.Ws.g(hG,mG);$CLJS.Y($CLJS.$F,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime truncation unit"],null)],null),$CLJS.pG));$CLJS.qG=$CLJS.Ws.g(fG,kG);$CLJS.Y(lha,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime extraction unit"],null)],null),$CLJS.qG));
var rG=$CLJS.Yd.g(hG,$CLJS.Wh);$CLJS.Y($CLJS.dG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid date interval unit"],null)],null),rG));$CLJS.Y($CLJS.aG,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid time interval unit"],null)],null),mG));var wha=$CLJS.Ws.g(rG,mG);$CLJS.Y($CLJS.cD,$CLJS.Wf.g(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.br,new $CLJS.h(null,1,[$CLJS.ts,"Valid datetime interval unit"],null)],null),wha));
$CLJS.Y(qha,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.cG],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.ei,$CLJS.dD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Oh,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null));