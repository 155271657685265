var window=global;var $CLJS=require("./cljs_env.js");require("./cljs.core.js");require("./metabase.lib.schema.common.js");require("./metabase.lib.schema.expression.js");require("./metabase.lib.schema.mbql_clause.js");require("./metabase.lib.schema.temporal_bucketing.js");require("./metabase.util.malli.registry.js");
'use strict';var PI;$CLJS.NI=function(a){return $CLJS.n($CLJS.pB($CLJS.sd,$CLJS.rd,$CLJS.Yk)(a))?a:new $CLJS.P(null,1,5,$CLJS.Q,[a],null)};
$CLJS.OI=function(a,b){return $CLJS.Me($CLJS.Ad,function(){return function e(d){return new $CLJS.ie(null,function(){for(var f=d;;){var k=$CLJS.y(f);if(k){var l=k,m=$CLJS.z(l);if(k=$CLJS.y(function(t,u,v,x){return function G(C){return new $CLJS.ie(null,function(J,S,X,W){return function(){for(var da=C;;){var ra=$CLJS.y(da);if(ra){var Ma=ra,sb=$CLJS.z(Ma);if(ra=$CLJS.y(function(Qa,Xa,Ja,Va,Ta,mb,dc,ed){return function Rh(hd){return new $CLJS.ie(null,function(sz,Jl,Wn,Ar,U_,Nv){return function(){for(;;){var Ov=
$CLJS.y(hd);if(Ov){if($CLJS.wd(Ov)){var Pv=$CLJS.ic(Ov),tz=$CLJS.D(Pv),Fk=$CLJS.le(tz);a:for(var Br=0;;)if(Br<tz){var gt=$CLJS.id(Pv,Br);gt=$CLJS.E.g(Nv,$CLJS.aE)||$CLJS.E.g(Wn,$CLJS.aE)||$CLJS.CE(Nv,gt)&&$CLJS.CE(Wn,gt);Fk.add(gt);Br+=1}else{Pv=!0;break a}return Pv?$CLJS.oe($CLJS.qe(Fk),Rh($CLJS.jc(Ov))):$CLJS.oe($CLJS.qe(Fk),null)}Fk=$CLJS.z(Ov);return $CLJS.ae($CLJS.E.g(Nv,$CLJS.aE)||$CLJS.E.g(Wn,$CLJS.aE)||$CLJS.CE(Nv,Fk)&&$CLJS.CE(Wn,Fk),Rh($CLJS.Hc(Ov)))}return null}}}(Qa,Xa,Ja,Va,Ta,mb,dc,
ed),null,null)}}(da,J,sb,Ma,ra,S,X,W)($CLJS.mE)))return $CLJS.bf.g(ra,G($CLJS.Hc(da)));da=$CLJS.Hc(da)}else return null}}}(t,u,v,x),null,null)}}(f,m,l,k)($CLJS.NI($CLJS.OD(b)))))return $CLJS.bf.g(k,e($CLJS.Hc(f)));f=$CLJS.Hc(f)}else return null}},null,null)}($CLJS.NI($CLJS.OD(a)))}())};
PI=function(a){return function(){function b(d,e){var f=null;if(1<arguments.length){f=0;for(var k=Array(arguments.length-1);f<k.length;)k[f]=arguments[f+1],++f;f=new $CLJS.w(k,0,null)}return c.call(this,d,f)}function c(d,e){return new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.lr,$CLJS.Ne.j($CLJS.yE,d,e),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ai,new $CLJS.h(null,1,[$CLJS.ts,"arguments should be comparable"],null),function(f){f=$CLJS.y(f);$CLJS.z(f);f=$CLJS.B(f);$CLJS.z(f);f=$CLJS.B(f);var k=$CLJS.yf(f);f=$CLJS.QD;
return $CLJS.n(f)?f:$CLJS.Le($CLJS.il,$CLJS.cf.g(function(l){var m=$CLJS.I(l,0,null);l=$CLJS.I(l,1,null);return $CLJS.OI($CLJS.K.g(k,m),$CLJS.K.g(k,l))},a))}],null)],null)}b.A=1;b.B=function(d){var e=$CLJS.z(d);d=$CLJS.Hc(d);return c(e,d)};b.l=c;return b}()};$CLJS.QI=new $CLJS.M("metabase.lib.schema.filter","operator","metabase.lib.schema.filter/operator",-805746630);$CLJS.RI=new $CLJS.M("operator","filter","operator/filter",-1518842858);
$CLJS.SI=new $CLJS.M(null,"display-name-variant","display-name-variant",-1831788853);for(var TI=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.lr,$CLJS.er],null)),UI=null,VI=0,WI=0;;)if(WI<VI){var Kia=UI.X(null,WI);$CLJS.BE(Kia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)],null)],null)],null)]));WI+=1}else{var XI=$CLJS.y(TI);if(XI){var YI=XI;if($CLJS.wd(YI)){var ZI=$CLJS.ic(YI),Lia=$CLJS.jc(YI),
Mia=ZI,Nia=$CLJS.D(ZI);TI=Lia;UI=Mia;VI=Nia}else{var Oia=$CLJS.z(YI);$CLJS.BE(Oia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)],null)],null)],null)]));TI=$CLJS.B(YI);UI=null;VI=0}WI=0}else break}$CLJS.zE($CLJS.gr,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.ZD],null)]));
for(var $I=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.CF],null)),aJ=null,bJ=0,cJ=0;;)if(cJ<bJ){var Pia=aJ.X(null,cJ);$CLJS.BE(Pia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dE],null)],null)],null)],null)]));cJ+=1}else{var dJ=$CLJS.y($I);if(dJ){var eJ=dJ;if($CLJS.wd(eJ)){var fJ=$CLJS.ic(eJ),Qia=$CLJS.jc(eJ),
Ria=fJ,Sia=$CLJS.D(fJ);$I=Qia;aJ=Ria;bJ=Sia}else{var Tia=$CLJS.z(eJ);$CLJS.BE(Tia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Kr,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Rq,new $CLJS.h(null,1,[$CLJS.si,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Pk,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.dE],null)],null)],null)],null)]));$I=$CLJS.B(eJ);aJ=null;bJ=0}cJ=0}else break}
for(var gJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.Eq,$CLJS.Gq,$CLJS.Aq,$CLJS.Cq],null)),hJ=null,iJ=0,jJ=0;;)if(jJ<iJ){var Uia=hJ.X(null,jJ);$CLJS.xE.l(PI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Uia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)]));jJ+=1}else{var kJ=$CLJS.y(gJ);if(kJ){var lJ=kJ;if($CLJS.wd(lJ)){var mJ=$CLJS.ic(lJ),Via=$CLJS.jc(lJ),Wia=mJ,Xia=$CLJS.D(mJ);gJ=Via;hJ=Wia;iJ=Xia}else{var Yia=
$CLJS.z(lJ);$CLJS.xE.l(PI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),Yia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)]));gJ=$CLJS.B(lJ);hJ=null;iJ=0}jJ=0}else break}
$CLJS.xE.l(PI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,1],null)])),$CLJS.OE,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)]));
$CLJS.xE.l(PI($CLJS.Tg([new $CLJS.P(null,2,5,$CLJS.Q,[1,3],null),new $CLJS.P(null,2,5,$CLJS.Q,[1,5],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,2],null),new $CLJS.P(null,2,5,$CLJS.Q,[0,4],null)])),$CLJS.UE,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.fE],null)]));
for(var nJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.NE,$CLJS.ZE],null)),oJ=null,pJ=0,qJ=0;;)if(qJ<pJ){var Zia=oJ.X(null,qJ);$CLJS.zE(Zia,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)]));qJ+=1}else{var rJ=$CLJS.y(nJ);if(rJ){var sJ=rJ;if($CLJS.wd(sJ)){var tJ=$CLJS.ic(sJ),$ia=$CLJS.jc(sJ),aja=tJ,bja=$CLJS.D(tJ);nJ=$ia;oJ=aja;pJ=bja}else{var cja=$CLJS.z(sJ);$CLJS.zE(cja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)]));nJ=$CLJS.B(sJ);
oJ=null;pJ=0}qJ=0}else break}
for(var uJ=$CLJS.y(new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.PE,$CLJS.WE],null)),vJ=null,wJ=0,xJ=0;;)if(xJ<wJ){var dja=vJ.X(null,xJ);$CLJS.zE(dja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)]));xJ+=1}else{var yJ=$CLJS.y(uJ);if(yJ){var zJ=yJ;if($CLJS.wd(zJ)){var AJ=$CLJS.ic(zJ),eja=$CLJS.jc(zJ),fja=AJ,gja=$CLJS.D(AJ);uJ=eja;vJ=fja;wJ=gja}else{var hja=$CLJS.z(zJ);$CLJS.zE(hja,$CLJS.H([$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.$D],null)]));uJ=$CLJS.B(zJ);
vJ=null;wJ=0}xJ=0}else break}
for(var BJ=new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.uF,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null),CJ=$CLJS.y(new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.dF,$CLJS.xF,$CLJS.lF,$CLJS.eF],null)),DJ=null,EJ=0,FJ=0;;)if(FJ<EJ){var GJ=DJ.X(null,FJ);$CLJS.LF.v(GJ,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,GJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.xD,BJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,
$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null)],null));FJ+=1}else{var HJ=$CLJS.y(CJ);if(HJ){var IJ=HJ;if($CLJS.wd(IJ)){var JJ=$CLJS.ic(IJ),ija=$CLJS.jc(IJ),jja=JJ,kja=$CLJS.D(JJ);CJ=ija;DJ=jja;EJ=kja}else{var KJ=$CLJS.z(IJ);$CLJS.LF.v(KJ,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,5,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,KJ],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.xD,BJ],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.iE],null),new $CLJS.P(null,2,5,$CLJS.Q,
[$CLJS.V,$CLJS.iE],null)],null));CJ=$CLJS.B(IJ);DJ=null;EJ=0}FJ=0}else break}
$CLJS.LF.v($CLJS.cB,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,6,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.cB],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.ss,$CLJS.xD,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.Ik,new $CLJS.h(null,1,[$CLJS.yr,!0],null),$CLJS.ur],null)],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.XD],null),new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.br,$CLJS.Qu,$CLJS.Vw,$CLJS.TE],null),new $CLJS.P(null,
2,5,$CLJS.Q,[$CLJS.V,$CLJS.lE],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.V,$CLJS.cD],null)],null));$CLJS.LF.v($CLJS.KE,$CLJS.Zr,$CLJS.lj,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.hr,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.KE],null),$CLJS.xD,new $CLJS.P(null,3,5,$CLJS.Q,[$CLJS.er,$CLJS.jD,$CLJS.sD],null)],null));
$CLJS.Y($CLJS.QI,new $CLJS.P(null,4,5,$CLJS.Q,[$CLJS.zj,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Vs,new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.Hq,$CLJS.RI],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.fF,new $CLJS.P(null,17,5,$CLJS.Q,[$CLJS.br,$CLJS.Hq,$CLJS.CF,$CLJS.UE,$CLJS.OE,$CLJS.Eq,$CLJS.Aq,$CLJS.Gq,$CLJS.Cq,$CLJS.NE,$CLJS.ZE,$CLJS.PE,$CLJS.WE,$CLJS.lF,$CLJS.eF,$CLJS.dF,$CLJS.xF],null)],null),new $CLJS.P(null,2,5,$CLJS.Q,[$CLJS.SI,$CLJS.Fi],null)],null));